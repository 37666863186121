import React from 'react'
import { Modal } from "react-bootstrap";
const DescriptioModal = ({ show, onHide, header, body, onclick }) => {
  return (
    <div className="descriptionModel">
    <Modal
      show={show}
      onHide={onHide}
      className="descriptionModel"
      centered={true}
    >
      <Modal.Header>
        <p>{header}</p>
        <i
          onClick={onclick}
          className="fa fa-times icon-close"
          aria-hidden="true"
        ></i>
      </Modal.Header>
      <Modal.Body>
      <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: body }}></div>
      </Modal.Body>
    </Modal>
    </div>
  )
}

export default DescriptioModal