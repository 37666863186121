
import React, { useState } from "react";
import Auth from "../../../../../helpers/Auth";
import { getUserInfo } from "../../../../../utils/user.util";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import {  Power } from "react-feather";
import { Link } from "react-router-dom";


export function UserProfileDropdown() {
  let userInfo = getUserInfo();

  const Logout = async () => {
    await Auth.deauthenticateLocalUser();
    window.location.reload();
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* <UncontrolledDropdown tag="li" className="dropdown-user nav-item"> */}
      <Dropdown
        isOpen={isOpen}
        toggle={toggle}
        className="dropdown-user nav-item"
      >
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            color: "#191235",
          }}
        >
          {userInfo?.role === "admin" ? (
            <>
              <div className="user-nav d-sm-flex d-none ">
                <span
                  className="user-name fw-bold col"
                  style={{ fontWeight: "bold" }}
                >
                   Admin
                </span>
              </div>
              {/* <Avatar src={superadmin} imgHeight="40" imgWidth="40" status="online" /> */}
              <span className="symbol symbol-35 symbol-light-primary">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  {userInfo?.role?.toUpperCase()[0] + ". "}
                </span>
              </span>
            </>
          ) : (
            <>
              <span className="text-dark-50 font-weight-bolder font-size-base d-md-inline mr-3">
                {userInfo?.role?.toUpperCase() + " "}
              </span>
            </>
          )}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag={Link} to="/auth/login" onClick={Logout}>
            <Power size={14} className="me-75" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* </UncontrolledDropdown> */}
    </>
  );
}
