import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { Input, Label, Textarea } from "reactstrap";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import { customStyles } from "../tableStyle";
import "./youtube.scss";

export default function YoutubeKey() {
  const [keyData, setKeyData] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [countPerPage, setCountPerPage] = useState(10);
  const [inputValue, setInputValue] = useState();
  const [isModel, setIsModel] = useState(false);
  const [formType, setFormType] = useState("add");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState("");

  const validation = () => {
    let isValid = true;
    let error = {};
    if (!inputValue?.ChannelId?.trim()) {
      error["ChannelId"] = "Please enter value";
      isValid = false;
    }
    if (!inputValue?.channelName?.trim()) {
      error["channelName"] = "Please enter value";
      isValid = false;
    }
    setError(error);
    return isValid;
  };

  const youtubeData = async () => {
    try {
      setLoading(true);
      let resp = await ApiGet(
        `youtubeChannel/getAllYoutubeId?page=${page}&limit=${countPerPage}`
      );
      setKeyData(resp?.data?.payload?.findAllId);
      setCount(resp?.data?.payload?.count);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
      setLoading(false);
    }
  };
  const addKey = async () => {
    let body = {
      channelId: inputValue?.ChannelId,
      channelName:inputValue?.channelName
    };

    if (validation()) {
      try {
        setButtonLoading(true);
        let resp = await ApiPost(`youtubeChannel/addYoutubeId`, body);
        if (resp) {
          youtubeData();
          setIsModel(!isModel);
          setButtonLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong!");
        setButtonLoading(false);
        setIsModel(!isModel);
      }
    }
  };

  const keyUpdate = () => {
    let body = {
      channelId: inputValue?.ChannelId,
      channelName:inputValue?.channelName
    };

    if (validation()) {
      try {
        let resp = ApiPut(
          `youtubeChannel/updateYoutube?id=${inputValue?.id}`,
          body
        );
        if (resp) {
          youtubeData();
          setIsModel(!isModel);
          setButtonLoading(false);
          youtubeData();
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong!");
        setButtonLoading(false);
        setIsModel(!isModel);
      }
    }
  };

  useEffect(() => {
    youtubeData();
  }, []);

  const deleteChannel = async (id) => {
    try {
      setLoading(true);
      let resp = await ApiDelete(`youtubeChannel/deleteChannel?id=${id}`);
      toast.success("Sucessfully deleted");
      youtubeData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
      setLoading(false);
    }
  };
  const updateDeatils = (row) => {
    setIsModel(!isModel);
    setFormType("update");
    setInputValue({ ...inputValue, ChannelId: row?.channelId, id: row?._id,channelName:row?.channelName });
  };
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => {
        return <p>{index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "Created Date",
      width: "150px",
      cell: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD MMM YYYY" date={row?.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
      selector: (row) => row?.createdAt,
      sortable: true,
    },
    {
        name: "Updated Date",
        width: "150px",
        cell: (row) => (
          <div className="profileImage">
            {row.createdAt ? (
              <Moment format="DD MMM YYYY" date={row?.updatedAt} />
            ) : (
              "-"
            )}
          </div>
        ),
        selector: (row) => row?.updatedAt,
        sortable: true,
      },
    {
      minWidth: "200px",
      name: "Channel Id",
      cell: (row) => (
        <div  className="profileImage">
          {row?.channelId ? row?.channelId : "-"}
        </div>
      ),
      selector: (row) => row?.channelId,
      sortable: true,
    },
    {
      minWidth: "200px",
      name: "Channel Name",
      cell: (row) => (
        <div className="profileImage">
          {row?.channelName ? row?.channelName : "-"}
        </div>
      ),
      selector: (row) => row?.channelName,
      sortable: true,
    },
    {
      minWidth: "250px",
      name: "Action",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => updateDeatils(row)}
          >
            <i className="pencilIcon fa fa-pencil" aria-hidden="true"></i>
          </Button>

          <Button
            className="btn-btn-new-class"
            onClick={() => deleteChannel(row?._id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  const closeModel = () => {
    setIsModel(!isModel);
    setInputValue("");
  };
  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-4 d-flex align-items-center">
              <h2 className="pl-3 pt-2">Youtube Channel key</h2>
            </div>
            <div className="col-lg-8 justify-content-end align-items-center row">
              
              <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                <button
                  className="btn btn addbutton"
                  onClick={() => {
                    setIsModel(!isModel);
                    setFormType("add");
                  }}
                >
                  Add New
                </button>
              </div>
            </div>
          </div>
          <DataTable
            data={keyData}
            columns={columns}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>

        <div className="new-youtube-model" style={{ width: "10px" }}>
          <Modal
            show={isModel}
            onHide={closeModel}
            className="d-flex justify-content-center align-items-center new-youtube-model"
          >
            <Modal.Header>
              <Modal.Title style={{ color: "#191235" }}>
                {formType == "add"
                  ? "Add Youtube Channel key"
                  : "Update Youtube Channel key"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row pb-5 ">
                  <div className="col-6 col-md-12  ">
                    <Label className="form-label" for="name">
                      Channel Id<span className="text-danger"> *</span>
                    </Label>

                    <Input
                      placeholder="Channel Id"
                      name="channelId"
                      value={inputValue?.ChannelId}
                      onChange={(event) => {
                        setInputValue({
                          ...inputValue,
                          ChannelId: event.target.value,
                        });
                        setError({...error,ChannelId:""});
                      }}
                    />
                    <p style={{color:"red"}}>{error?.ChannelId}</p>
                  </div>
                  <div className="col-6 col-md-12  ">
                    <Label className="form-label" for="name">
                      Channel Name<span className="text-danger"> *</span>
                    </Label>

                    <Input
                      placeholder="Channel Name"
                      name="channelName"
                      value={inputValue?.channelName}
                      onChange={(event) => {
                        setInputValue({
                          ...inputValue,
                          channelName: event.target.value,
                        });
                        setError({...error,channelName:""});
                      }}
                    />
                    <p style={{color:"red"}}>{error?.channelName}</p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="activebutton h-40px w-70px"
                onClick={() => closeModel()}
                style={{ marginRight: "15px" }}
              >
                Cancel
              </Button>
              <Button
                className="activebutton h-40px w-70px"
                onClick={formType == "add" ? addKey : keyUpdate}
                style={{ marginRight: "15px" }}
              >
                {formType == "add" ? " Add " : "Update"}
                {buttonLoading && (
                  <div class="h-20px spinner-border text-green w-20px ml-2"></div>
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
