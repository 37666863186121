import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { ApiGet } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export function Demo1Dashboard() {
  const [dashboardCount, setDashboardCount] = useState({});
  const[userCount,setUserCount]=useState(null)

  useEffect(() => {
    handleGetDashboardCount();
  }, []);
  const handleGetDashboardCount = async () => {
    await ApiGet(`TeleScope/dashboard`)
      .then((response) => {
        const data = response?.data?.payload?.keyValuePairs;
        setDashboardCount(data);
        setUserCount(response?.data?.payload?.userCount)
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <div className=" ">
        <div className="col-lg-12 col-xxl-12 p-0">
          <div className="row">

          <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/userlist">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                         User List
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={userCount|| 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                      <i className="fas  fa-users fa-2x  text-danger-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/solarsystemconstellation">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                        Solar System & Constellations
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount?.SolarSystemConstellations || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                      <i class="fa-solid fa-sun" style={{fontSize:"25px"}}aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/spaceorganisation">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                        Space Organisations
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount?.SpaceOrganisations
                              || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                      <i class="fa-solid fa-bullseye"  style={{fontSize:"25px"}}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/celestioalboadies">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                        Celestial Bodies & Wonders
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp end={dashboardCount?.CelestialBodiesWonders || 0} />
                        </div>
                      </div>
                      <div className="col-auto">
                      <i class="fa-solid fa-meteor" style={{fontSize:"25px"}}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/scientistastro">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                        Scientists and Astronauts
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount?.ScientistsandAstronauts || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto dashboardlogo">
                      <i class="fa-solid fa-user-astronaut" style={{fontSize:"25px"}}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <NavLink className="" to="/eventsachivments">
                <div className="card rounded-3 border-5 border-bottom shadow h-100 py-2">
                  <div className="card-body cursor-pointer">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-sjma text-uppercase mb-1">
                        Events and achievements
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-danger-800">
                          <CountUp
                            end={dashboardCount?.Eventsandachievements || 0}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                      <i class="fa-solid fa-calendar" style={{fontSize:"25px"}}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
