import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import { getUserInfo } from "../utils/user.util";
import SolarSystemManagement from "../_metronic/components/KnowledgeGalaxy/SolarSystem/index.jsx";
import SpaceOrganisation from "../_metronic/components/KnowledgeGalaxy/SpaceOrganisation/index.jsx";
import CelestialBodies from "../_metronic/components/KnowledgeGalaxy/CelestialBodies/index.jsx";
import SceintistAstro from "../_metronic/components/KnowledgeGalaxy/SceintistAndAustro/index.jsx";
import EventsAchivments from "../_metronic/components/KnowledgeGalaxy/EventAchivments/index.jsx";
import UserList from "../_metronic/components/UserList/index.jsx";
import YoutubeKey from "../_metronic/components/YoutubeChannel/index.js";

export default function BasePage() {
  let userInfo = getUserInfo();

  return (
    <>
      {userInfo.role === "admin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <ContentRoute path="/userlist" component={UserList} />
            <ContentRoute
              path="/solarsystemconstellation"
              component={SolarSystemManagement}
            />
            <ContentRoute
              path="/spaceorganisation"
              component={SpaceOrganisation}
            />
            <ContentRoute
              path="/celestioalboadies"
              component={CelestialBodies}
            />
            <ContentRoute path="/scientistastro" component={SceintistAstro} />
            <ContentRoute
              path="/eventsachivments"
              component={EventsAchivments}
            />
            <ContentRoute
              path="/youtubechannel"
              component={YoutubeKey}
            />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}
    </>
  );
}
