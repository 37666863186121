import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { Input, Label, Textarea } from "reactstrap";
import { useLocation } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import Select from "react-select";
import closeicon from "../../../../assets/icon/logos/images.png";
function AddSolarSystem(props) {
  const [subTyp, setsubTyp] = useState([]);
  const {
    inputValue,
    handleChange,
    errors,
    formType,
    handleAddNews,
    setSelectedSubType,
    setSelectedOption,
    selectedOption,
    selectedSubType,
    buttonLoading,
    loading,
    handleOnClose,
    setInputValue,
    setErrors,
    isDropdownVisible,
    setupdateImages,
    updateImage,
    setUpdateAudio,
    updateAudio,
    parentType,
  } = props;
  const subtypes = {
    "/solarsystemconstellation": ["Constellations", "Planets", "Satellites"],
    "/scientistastro": ["Scientists", "Astronauts"],
    "/spaceorganisation": ["SpaceSuits", "Launchers"],
  };
 
  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handlenewChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleChangeSubType = (selectedOption) => {
    setSelectedSubType(selectedOption);
    // You can perform any additional actions here when the sub type is selected
  };
  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        if (file.size <= 20 * 1024 * 1024) {
          setupdateImages(URL.createObjectURL(file));
          setInputValue({ ...inputValue, photo: file });
          setErrors({ ...errors, photo: "" });
        } else {
          setErrors({
            ...errors,
            photo: "Please select an image smaller than 20 MB",
          });
          event.target.value = null;
        }
      } else {
        setErrors({ ...errors, photo: "Please select a JPEG or PNG image." });
        event.target.value = null;
      }
    }
  };
  const changeEditorValue = (content, name) => {
    setInputValue((newvalue) => ({ ...newvalue, description: content }));
    setErrors((prevErrors) => ({ ...prevErrors, description: "" }));
  };
  const location = useLocation();
  useEffect(() => {
    setsubTyp(subtypes[location.pathname]);
  }, []);
  const handleNameChange = (event) => {
    const { value } = event.target;
    if (value.startsWith(" ")) {
      const trimmedValue = value.trimStart();
      const capitalizedValue =
        trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
      setInputValue({ ...inputValue, name: capitalizedValue });
    } else {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setInputValue({ ...inputValue, name: capitalizedValue });
    }
    setErrors({ ...errors, name: "" });
  };
  const handleAudio = (event) => {
    const newaudioFile = event.target.files[0];
    if (newaudioFile) {
      const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
      const maxSize = 50 * 1024 * 1024;

      if (allowedTypes.includes(newaudioFile.type)) {
        if (newaudioFile.size <= maxSize) {
          setUpdateAudio(URL.createObjectURL(newaudioFile));
          setInputValue({ ...inputValue, audioFile: newaudioFile });
          setErrors({ ...errors, audioFile: "" });
        } else {
          setErrors({
            ...errors,
            audioFile: "Maximum file size is 50 MB.",
          });
        }
      } else {
        setErrors({
          ...errors,
          audioFile: "Please select a valid audio file (MP3, WAV, etc.).",
        });
        event.target.value = null;
      }
    }
  };
  const handlenewOnClose = () => {
    setInputValue({ ...inputValue, audioFile: "" });
    setUpdateAudio("");
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      maxHeight: "100px",
      width: "200px",
      minHeight: "unset",
      overflow: "auto",
    }),
  };

  return (
    <>
      <>
        <div className="new-model-design">
          <div className="modal-content">
            <Modal.Header className="header-alignment-model-design">
              <Modal.Title style={{ color: "#191235" }}>
                {formType == "add" ? "Add Details" : "Update Details"}
                <div className="button-alig">
                  <Button
                    className="activebutton h-40px"
                    onClick={() => handleOnClose()}
                    style={{ marginRight: "15px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={loading}
                    className="addbutton"
                    onClick={() => handleAddNews()}
                  >
                    <span>{formType == "add" ? "Add" : "Update"}</span>
                    {(buttonLoading || loading) && (
                      <div class="h-20px spinner-border text-light w-20px ml-2"></div>
                    )}
                  </Button>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="model-alignment w-100">
                <div className="row pb-5 w-50 ">
                  <div className="col-6 col-md-6  ">
                    <Label className="form-label" for="name">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <span className="errors"> {errors?.name}</span>

                    <Input
                      placeholder="Enter name"
                      name="name"
                      value={inputValue?.name}
                      onChange={(event) => handleNameChange(event)}
                      invalid={errors?.name && "true"}
                    />
                  </div>

                  <div className="col-6 col-md-6 ">
                    <Label className="form-label" for="positionNo">
                      Position No<span className="text-danger">*</span>
                    </Label>
                    <span className="errors">{errors?.positionNo}</span>

                    <Input
                      placeholder="Enter Position no"
                      name="positionNo"
                      value={inputValue?.positionNo}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.positionNo && "true"}
                      onKeyPress={bindInput}
                    />
                  </div>

                  {isDropdownVisible && (
                    <div className="col-6 col-md-6">
                      <Label className="form-label" for="type">
                        Sub Type <span className="text-danger"></span>
                      </Label>
                      <span className="errors">{errors?.subtype}</span>
                      <div className="select-design">
                        <Select
                          className="select-handle"
                          onChange={handleChangeSubType}
                          options={subTyp.map((item) => ({
                            value: item,
                            label:
                              item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase(),
                          }))}
                          name="subtype"
                          styles={customStyles}
                          defaultValue={selectedSubType}
                          placeholder="Please Select"
                          isInvalid={!!errors?.subtype}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-6 col-md-6">
                    <Label className="form-label" for="type">
                      Parent Type <span className="text-danger"></span>
                    </Label>
                    <div className="select-design">
                      <Select
                        className="select-handle"
                        onChange={handlenewChange}
                        options={[
                          { value: null, label: 'No Parent' }, // Add this option
                          ...parentType.map((item) => ({
                            value: item?._id,
                            label: item?.name,
                          }))
                        ]}
                        name="pid"
                        value={selectedOption}
                        placeholder="Please Select"
                        styles={customStyles}
                      />
                    </div>
                  </div>

                  <div className="col-6 col-md-6  ">
                    <Label className="form-label" for="artext">
                      AR Text
                    </Label>
                    <span className="errors">{errors?.artext}</span>

                    <Input
                      placeholder="Enter text"
                      name="artext"
                      value={inputValue?.artext}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.artext && "true"}
                    />
                  </div>
                  <div className="col-6 col-md-6 ">
                    <Label className="form-label" for="artext">
                      3D Text
                    </Label>
                    <span className="errors">{errors?.d3text}</span>

                    <Input
                      placeholder="Enter text"
                      name="d3text"
                      value={inputValue?.d3text}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.d3text && "true"}
                    />
                  </div>

                  <div className="col-6 col-md-6 ">
                    <Label className="form-label" for="artext">
                      Skymap Text
                    </Label>
                    <span className="errors">{errors?.skymaptext}</span>

                    <Input
                      placeholder="Enter text"
                      name="skymaptext"
                      value={inputValue?.skymaptext}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.skymaptext && "true"}
                    />
                  </div>
                  <div className="col-6 col-md-6  ">
                    <Label className="form-label" for="artext">
                      Audio Text <span className="text-danger">*</span>
                    </Label>
                    <span className="errors">{errors?.audio}</span>

                    <Input
                      placeholder="Enter text"
                      name="audio"
                      value={inputValue?.audio}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.audio && "true"}
                    />
                  </div>

                  <div className="col-6 col-md-6">
                    <Label className="form-label" for="sort description">
                      Did You Know That
                    </Label>
                    <span className="errors">{errors?.sortDescription}</span>

                    <Input
                      type="text"
                      placeholder="Enter text "
                      name="sortDescription"
                      value={inputValue?.sortDescription}
                      onChange={(event) => handleChange(event)}
                      invalid={errors?.sortDescription && "true"}
                    />
                  </div>
                  <div className="col-6 col-md-6">
                    <Label className="form-label" for="sort description">
                      Image <span className="text-danger">*</span>
                      <a>(220px*220px prefered)</a>
                    </Label>
                    <span className="errors">{errors?.photo}</span>

                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleImage(event)}
                      invalid={errors?.photo && "true"}
                    />
                  </div>

                  <div className="col-6 col-md-6">
                    <Label className="form-label" for="sort description">
                      Audio File <span className="text-danger">*</span>
                    </Label>
                    <span className="errors">{errors?.audioFile}</span>

                    <Input
                      type="file"
                      accept="audio/*"
                      onChange={(event) => handleAudio(event)}
                      invalid={errors?.audioFile && "true"}
                    />
                  </div>
                  <div className="col-6 col-md-6">
                    {inputValue?.photo && (
                      <div className="new-modal-image-alignment">
                        <div className="image-alignment">
                          <i
                            class="fas fa-window-close"
                            onClick={() =>
                              setInputValue({ ...inputValue, photo: "" })
                            }
                          ></i>
                          {/* <div className="new-icon">    <img src={closeicon}/> </div> */}
                          <img
                            src={
                              formType === "add"
                                ? URL.createObjectURL(inputValue?.photo)
                                : updateImage
                                ? updateImage
                                : inputValue?.photo
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6">
                    {inputValue?.audioFile && (
                      <div className="new-audio-file">
                        <div className="audio-control-alignment">
                          <i
                            class="fas fa-window-close"
                            onClick={() => handlenewOnClose()}
                          ></i>

                          <audio controls>
                            {inputValue?.audioFile && (
                              <source
                                src={
                                  formType === "add"
                                    ? URL.createObjectURL(inputValue?.audioFile)
                                    : updateAudio
                                    ? updateAudio
                                    : inputValue?.audioFile
                                }
                              />
                            )}
                          </audio>
                          {/* <source src={inputValue.audioFile} type="audio/mpeg" /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className=" w-50 ">
                  <Label className="form-label" for="description">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <span className="errors">{errors?.description}</span>

                  <SunEditor
                    name="description"
                    defaultValue={inputValue?.description}
                    onChange={(content) => changeEditorValue(content)}
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "superscript",
                          "subscript",
                        ],
                        ["removeFormat"],
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table"],
                        ["link", "image", "video"],
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview"],
                      ],
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </>
    </>
  );
}

export default AddSolarSystem;
