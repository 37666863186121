import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";
import "./sideMenu.scss";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  let arrayOfSubMenu = [
    "/solarsystemconstellation",
    "/spaceorganisation",
    "/celestioalboadies",
    "/scientistastro",
    "/eventsachivments",
  ];
    
  useEffect(() => {
    if (arrayOfSubMenu?.includes(location?.pathname)) {
      setIsMenuOpen(true);
    }
  }, [location?.pathname]);
  return (
    <>
      {userInfo?.role === "admin" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`} ref={ref}>
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <i class="fa fa-tachometer" aria-hidden="true"></i>
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/userlist", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/userlist">
              <span className="svg-icon menu-icon">
                <i class="fas fa-user"></i>
              </span>
              <span className="menu-text">User List</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive(false)}`}
            aria-haspopup="true"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <p className="menu-link">
              <span className="svg-icon menu-icon">
                <i class="fa fa-sun-o" aria-hidden="true"></i>
              </span>
              <span className="menu-text">
                Knowledge-Galaxy{" "}
                {isMenuOpen ? (
                  <i class="fa-solid fa-chevron-up"></i>
                ) : (
                  <i class="fa-solid fa-chevron-down"></i>
                )}
              </span>
            </p>
          </li>
          
          {isMenuOpen && (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/solarsystemconstellation",
                  false
                )}`}
                aria-haspopup="true"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink className="menu-link" to="/solarsystemconstellation">
                <i class="fa-solid fa-sun"></i>
                  <span className="menu-text">
                  Solar System & Constellations
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/spaceorganisation",
                  false
                )}`}
                aria-haspopup="true"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink className="menu-link" to="/spaceorganisation">
                <i class="fa-solid fa-bullseye"></i>
                  <span className="menu-text">Space Organisations</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/celestioalboadies",
                  false
                )}`}
                aria-haspopup="true"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink className="menu-link" to="/celestioalboadies">
                <i class="fa-solid fa-meteor"></i>
               
                  <span className="menu-text">Celestial Bodies & Wonders</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/scientistastro",
                  false
                )}`}
                aria-haspopup="true"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink className="menu-link" to="/scientistastro">
                <i class="fa-solid fa-user-astronaut"></i>
                  <span className="menu-text">Scientists and Astronauts</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/eventsachivments",
                  false
                )}`}
                aria-haspopup="true"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink className="menu-link" to="eventsachivments">
                <i class="fa-solid fa-calendar"></i>
                  <span className="menu-text">Events and achievements</span>
                </NavLink>
              </li>
             
              
            </>
          )}
         <li
                className={`menu-item ${getMenuItemActive(
                  "/youtubechannel",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="youtubechannel">
                <i class="fa-brands fa-youtube"></i>
                  <span className="menu-text">Youtube Channel key</span>
                </NavLink>
              </li>
        </ul>
      )}
    </>
  );
}
