import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "react-moment";
import "react-datepicker/dist/react-datepicker.css";
import "../KnowledgeGalaxy/SolarSystem/solarsystem.scss";
import useDebounce from "../../../hooks/useDebounceHook";
import { ApiGet } from "../../../helpers/API/ApiData";
import "./userlist.scss";
import DownloaddataModel from "../KnowledgeGalaxy/DownloaddataModel";
import { Modal } from "react-bootstrap";

export default function UserList() {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const oneWeekAgo = new Date(); 
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); 
    return oneWeekAgo;
  });
  const [endDate, setEndDate] = useState(new Date());
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },

    {
      width: "380px",
      name: "User Name",
      selector: (row) => (
        <div className="profileImage">{row?.name ? row?.name : "-"}</div>
      ),
    },
    {
      width: "380px",
      name: "Phone no",
      selector: (row) => (
        <div className="profileImage">
          {row?.dialcode} {row?.phonenum}
        </div>
      ),
    },
    {
      name: "Created Date",
      width: "340px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD MMM YYYY" date={row?.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    handleGetNewsList();
  }, [debouncedSearchValue, page, countPerPage]);

  const handleChangeinput = (event) => {
    const { value } = event.target;
    if (!value.startsWith(" ")) {
      setSearchValue(event.target.value);
      setLoading(true);
    }
  };
  const handleGetNewsList = () => {
    setLoading(true);
    ApiGet(
      `user2/get?page=${page}&limit=${countPerPage}${
        searchValue ? `&search=${searchValue}` : ""
      }`
    )
      .then((resp) => {
        setUserData(resp?.data?.payload?.data);
        setCount(resp?.data?.payload?.count);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-2 d-flex align-items-center">
              <h2 className="pl-3 pt-2">User Details</h2>
            </div>
            <div className="col-lg-10 justify-content-end align-items-center row">
              <div className="col-10 col-lg-5">
                <div className="input-button-aligment">
                  <div className="">
                    <input
                      type="search"
                      className={`form-control form-control-lg form-control-solid custom-input`}
                      onChange={(e) => {
                        handleChangeinput(e);
                      }}
                      width={"300px"}
                      name="search"
                      value={searchValue}
                      placeholder="Search user name"
                    />
                  </div>
                  <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                    <button
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                      className="btn btn addbutton"
                    >
                      Export user list
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            data={userData}
            columns={columns}
            responsive
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />

          <Modal
            show={openModal}
            onHide={() => setOpenModal(!openModal)}
            className="d-flex justify-content-center align-items-center"
          >
            <DownloaddataModel
              setOpenModal={setOpenModal}
              openModal={openModal}
              setStartDate={setStartDate}
              startDate={startDate}
              setEndDate={setEndDate}
              endDate={endDate}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
