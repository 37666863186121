import { useState } from "react";

export const useOnChange = (initialValue) => {
  const [inputValue, setInputValue] = useState({positionNo:""});
  const [editorValue, setEditorValue] = useState({});
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.startsWith(' ')) {
      const trimmedValue = value.trimStart();
      setInputValue({ ...inputValue, [name]: trimmedValue });
    } else {
      setInputValue({ ...inputValue, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };
  
  return {
    inputValue,
    setInputValue,
    errors,
    setErrors,
    handleChange,
    loadingUpload,
    setLoadingUpload,
    editorValue,
    setEditorValue
  };
};
