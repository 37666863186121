import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../tableStyle";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Button } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import "../SolarSystem/solarsystem.scss";
import { Modal } from "react-bootstrap";
import {
  ApiDelete,
  ApiGet,
  ApiPatch,
  ApiPost,
} from "../../../../helpers/API/ApiData";
import { useOnChange } from "../../../../hooks/onChangeHooks";
import { ValidateForm } from "../SolarSystem/ValidateForm";
import useDebounce from "../../../../hooks/useDebounceHook";
import AddSolarSystem from "../SolarSystem/AddSolarsystem";
import CommonModal from "../CommonModal";
import BulkUploadImage from "../SolarSystem/BulkUploadImage";
import ImageCommonModal from "../imagemodal";
import DescriptioModal from "../DiscriptionModel";

export default function EventsAchivments() {
  const [bulkModel, setBulkModel] = useState(false);
  const [updateImage, setupdateImages] = useState(null);
  const [solarData, setSolarData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);
  const [updateAudio, setUpdateAudio] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [FormType, setFormType] = useState("");
  const [loading, setLoading] = useState(false);
  const [UpdatePassword, setUpdatePassword] = useState(false);
  const [buttonLoading, setbuttonloading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const [imageModal, setImageModal] = useState(false);
  const [photo, setPhoto] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [audio, setAudio] = useState(null);
  const [handleData, setHandleData] = useState({
    description: "",
    sortDescription: "",
    audioText: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [audioModal, setAudioModal] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isDescriptioModel, setIsDescriptioModel] = useState(false);
  const { inputValue, setInputValue, errors, setErrors, handleChange } =
    useOnChange({});
  const handleAudio = (data) => {
    setHandleData({ ...handleData, audioText: data });
    setAudioModal(!audioModal);
  };
  const newImagefuntion = (image) => {
    setImageModal(!imageModal);
    setPhoto(image);
  };
  const newAudiofunction = (audio) => {
    setAudio(audio);
    setIsModel(!isModel);
  };
  const handleDescription = (data) => {
    setHandleData({ ...handleData, sortDescription: data });
    setIsDescription(!isDescription);
  };
  const handlesortDescription = (data) => {
    setHandleData({ ...handleData, description: data });
    setIsDescriptioModel(!isDescriptioModel);
  };
  const [parentType, setParentType] = useState([]);
  const columns = [
    {
      name: (
        <input
          type="checkbox"
          checked={selectedRows?.length === solarData?.length}
          onClick={(e) => handleSelectAllRows(e.target.checked)}
        />
      ),
      cell: (row, index) => {
        return (
          <input
            type="checkbox"
            disabled={row.apiAdded}
            checked={selectedRows.includes(row._id)}
            onChange={() => handleSelectRow(row)}
          />
        );
      },
      width: "60px",
    },

    {
      width: "150px",
      name: "Position No",
      cell: (row) => (
        <div style={{ "white-space": "normal" }} className="profileImage">
          {row?.positionNo}
        </div>
      ),
      selector: (row) => row?.positionNo,
      sortable: true,
    },
    {
      minWidth: "200px",
      name: "Name",
      cell: (row) => (
        <div style={{ "white-space": "normal" }} className="profileImage">
          {row?.name ? row?.name : "-"}
        </div>
      ),
      selector: (row) => row?.name?.trimStart(),
      sortable: true,
    },

    {
      minWidth: "200px",
      name: "ParentType",
      cell: (row) => (
        <div style={{ "white-space": "normal" }} className="profileImage">
          {row?.pid?.name ? row?.pid?.name : "-"}
        </div>
      ),
      selector: (row) => row?.pid?.trimStart(),
      sortable: true,
    },
    {
      maxWidth: "200px",
      name: "Description",
      cell: (row) => (
        <div
          onClick={() => handlesortDescription(row?.description)}
          className="profileFoto"
        >
          view
        </div>
      ),
      // selector:(row)=>row?.description,
      // sortable:true
    },
    {
      width: "160px",
      name: "Did You Know That",
      cell: (row) => (
        <div
          onClick={() => handleDescription(row?.sortDescription)}
          className="profileFoto"
        >
          View
        </div>
      ),
      // selector:(row)=>row?.sortDescription,
      // sortable:true
    },
    {
      maxWidth: "50px",
      name: "Photo",
      selector: (row) => (
        <div
          onClick={() => newImagefuntion(row?.photo)}
          className="profileFoto"
        >
          {row?.photo ? "View" : "-"}
        </div>
      ),
    },
    {
      maxWidth: "50px",
      name: "Audio File",
      selector: (row) => (
        <div
          onClick={() => newAudiofunction(row?.audioFile)}
          className="profileFoto"
        >
          View
        </div>
      ),
    },
    {
      width: "250px",
      name: "Audio text",
      cell: (row) => (
        <div onClick={() => handleAudio(row?.audio)} className="profileFoto">
          {row?.audio ? "View" : "-"}
        </div>
      ),
      // selector:(row)=>row?.audio,
      // sortable:true
    },
    {
      width: "250px",
      name: "Ar text",
      cell: (row) => (
        <div className="profileImage">
          {row?.viewInAR?.trim() &&
          row?.viewInAR !== "null" &&
          row?.viewInAR !== "undefined"
            ? row?.viewInAR
            : "-"}
        </div>
      ),

      selector: (row) => row?.viewInAR?.trimStart(),
      sortable: true,
    },
    {
      width: "250px",
      name: " 3D text",
      cell: (row) => (
        <div className="profileImage">
          {row?.viewIn3D?.trim() &&
          row?.viewIn3D !== "null" &&
          row?.viewIn3D !== "undefined"
            ? row?.viewIn3D
            : "-"}
        </div>
      ),
      selector: (row) => row?.viewIn3D?.trimStart(),
      sortable: true,
    },
    {
      width: "250px",
      name: " Skymap text",
      cell: (row) => (
        <div className="profileImage">
          {row?.viewInSkymap?.trim() &&
          row?.viewInSkymap !== "null" &&
          row?.viewInSkymap !== "undefined"
            ? row?.viewInSkymap
            : "-"}
        </div>
      ),
      selector: (row) => row?.viewInSkymap?.trimStart(),
      sortable: true,
    },

    {
      name: "Created Date",
      width: "150px",
      cell: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD MMM YYYY" date={row?.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
      selector: (row) => row?.createdAt,
      sortable: true,
    },
    {
      minWidth: "250px",
      name: "Action",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => updateNewsData(row)}
          >
            <i className="pencilIcon fa fa-pencil" aria-hidden="true"></i>
          </Button>

          <Button
            className="btn-btn-new-class"
            onClick={() => handleConfirmDelete(row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  const handleChangeinput = (event) => {
    const { value } = event.target;
    if (!value.startsWith(" ")) {
      setSearchValue(event.target.value);
      setLoading(true);
    }
  };
  const handleAddNewsClose = () => {
    setIsAddUser(false);
    setInputValue({});
    setErrors({});
    setSelectedOption("");
    setFormType("add");
    setUpdatePassword(false);
    setbuttonloading(false);
  };
  const updateNewsData = (row) => {
    setInputValue({
      name: row?.name,
      description: row?.description,
      id: row?._id,
      type: "Events and achievements",
      sortDescription: row?.sortDescription,
      photo: row?.photo,
      audio: row?.audio,
      audioFile: row?.audioFile,
      artext: row?.viewInAR !== "null" ? row?.viewInAR : "",
      d3text: row?.viewIn3D !== "null" ? row?.viewIn3D : "",
      skymaptext: row?.viewInSkymap !== "null" ? row?.viewInSkymap : "",
      positionNo: row?.positionNo,
    });

    setSelectedOption({
      value: row?.pid?._id,
      label: row?.pid?.name,
    });
    setIsAddUser(true);
    setFormType("update");
  };
  const handleValidateForm = () => {
    let error = ValidateForm(inputValue, FormType, false);
    setErrors(error?.error);
    return error?.isValid;
  };
  const handleAddUser = () => {
    if (handleValidateForm()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name);
      formData.append("description", inputValue?.description);
      formData.append("type", "Events and achievements");
      formData.append("photo", inputValue?.photo);
      formData.append("viewInAR", inputValue?.artext ? inputValue?.artext : "");
      formData.append("viewIn3D", inputValue?.d3text ? inputValue?.d3text : "");
      formData.append(
        "sortDescription",
        inputValue?.sortDescription ? inputValue?.sortDescription : ""
      );
      formData.append(
        "viewInSkymap",
        inputValue?.skymaptext ? inputValue?.skymaptext : ""
      );
      formData.append("audio", inputValue?.audio);
      formData.append("audioFile", inputValue?.audioFile);
      formData.append("positionNo", inputValue?.positionNo);
      {
        selectedOption?.value && formData.append("pid", selectedOption?.value);
      }
      ApiPost(`TeleScope/create`, formData)
        .then((res) => {
          toast.success("Events & Achivements added successfully", {
            position: "top-center",
          });
          handleAddNewsClose();
          setLoading(false);
          handleGetNewsList();
        })
        .catch((err) => {
          toast.error("Something went Wrong!", {
            position: "top-center",
          });
          setLoading(false);
          handleAddNewsClose();
        });
    }
  };
  const handleSelectRow = (row) => {
    if (selectedRows.length == 0) {
      setSelectedRows([row._id]);
    } else if (selectedRows.includes(row._id)) {
      setSelectedRows(selectedRows.filter((item) => item !== row._id));
    } else {
      setSelectedRows([...selectedRows, row._id]);
    }
  };
  const handleSelectAllRows = (checked) => {
    if (checked) {
      const allRowIds = solarData
        .map((item) => {
          if (!item.apiAdded) {
            return item._id;
          }
        })
        .filter((id) => id !== undefined);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };
  const handleUpdateUser = (row) => {
    if (handleValidateForm()) {
      let formData = new FormData();
      formData.append("name", inputValue?.name);
      formData.append("description", inputValue?.description);
      formData.append("type", "Events and achievements");
      formData.append("photo", inputValue?.photo);
      formData.append("viewInAR", inputValue?.artext ? inputValue?.artext : "");
      formData.append("viewIn3D", inputValue?.d3text ? inputValue?.d3text : "");
      formData.append(
        "sortDescription",
        inputValue?.sortDescription ? inputValue?.sortDescription : ""
      );
      formData.append(
        "viewInSkymap",
        inputValue?.skymaptext ? inputValue?.skymaptext : ""
      );
      formData.append("audio", inputValue?.audio);
      formData.append("audioFile", inputValue?.audioFile);
      formData.append("positionNo", inputValue?.positionNo);
      formData.append("pid", selectedOption?.value ?selectedOption?.value  :null);
      setbuttonloading(true);
      ApiPatch(`TeleScope/update/${inputValue?.id}`, formData)
        .then((res) => {
          toast.success("Events & Achivements updated successfully", {
            position: "top-center",
          });
          setbuttonloading(false);
          handleAddNewsClose();
          setupdateImages("");
          setUpdateAudio("");
          handleGetNewsList();
        })
        .catch((err) => {
          toast.error("Something went Wrong!", {
            position: "top-center",
          });
          setbuttonloading(false);
        });
    }
  };
  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    ApiDelete(`TeleScope/delete/${row?._id}`)
      .then((resp) => {
        setLoading(false);
        handleGetNewsList();
      })
      .catch((err) => {
        toast.error("Something went Wrong!", {
          position: "top-center",
        });
        setLoading(false);
      });
  };
  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancle",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result?.value) {
        const res = await handleDeleteNewsInfo(row);
        if (res) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Data deleted sucessfully.",
            timer: 2000,
            showCancelButton: false,
            actions: false,
            showConfirmButton: false,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Deleted",
            text: "Data deleted sucessfully.",
            icon: "success",
            timer: 2000,
            showCancelButton: false,
            actions: false,
            showConfirmButton: false,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      }
    });
  };
  useEffect(() => {
    handleGetNewsList();
  }, [debouncedSearchValue, page, countPerPage]);
  const handleGetNewsList = async () => {
    setLoading(true);
    let type = encodeURIComponent("Events and achievements");
    ApiGet(
      `TeleScope/get?page=${page}&limit=${countPerPage}${
        searchValue ? `&search=${searchValue}` : ""
      }&type=${type}`
    )
      .then((resp) => {
        setSolarData(resp?.data?.payload?.result);
        setCount(resp?.data?.payload?.count);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-center",
        });
        setLoading(false);
      });
  };

  const handleMultipleData = () => {
    let ids = selectedRows?.map((item) => item);
    ApiDelete("TeleScope/deleteMultiple", ids).then((resp) => {
      handleGetNewsList();
      setSelectedRows("");
      toast.success("data deleted sucessfully");
    });
  };
  const getParent = async () => {
    let type = encodeURIComponent("Events and achievements");
    try {
      let resp = await ApiGet(`TeleScope/NoParent?type=${type}`);
      setParentType(resp?.data?.payload?.result);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getParent();
  }, []);
  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col-12 col-lg-4 d-flex align-items-center">
              <h2 className="pl-3 pt-2">Events and achievements</h2>
            </div>
            <div className="col-lg-8 justify-content-end align-items-center row">
              <div className="col col-lg-5">
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid custom-input `}
                    onChange={(e) => {
                      handleChangeinput(e);
                    }}
                    name="search"
                    value={searchValue}
                    placeholder="Search name"
                  />
                </div>
              </div>
              <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                <button
                  onClick={() => {
                    setIsAddUser(true);
                    setFormType("add");
                  }}
                  className="btn btn addbutton"
                >
                  Add New
                </button>
              </div>
              <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                <button
                  onClick={() => {
                    setBulkModel(true);
                  }}
                  className="btn btn addbutton"
                >
                  Bulk upload
                </button>
              </div>
              <div className="cus-medium-button-style button-height pr-2 mt-lg-0">
                <button
                  disabled={!selectedRows?.length}
                  onClick={handleMultipleData}
                  className="btn btn addbutton"
                >
                  Delete Multiple
                </button>
              </div>
            </div>
          </div>
          <DataTable
            data={solarData}
            columns={columns}
            responsive
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
        <Modal
          show={isAddUser}
          onHide={handleAddNewsClose}
          className="d-flex justify-content-center align-items-center"
        >
          <AddSolarSystem
            updateImage={updateImage}
            setupdateImages={setupdateImages}
            setUpdateAudio={setUpdateAudio}
            updateAudio={updateAudio}
            isDropdownVisible={false}
            UpdatePassword={UpdatePassword}
            setUpdatePassword={setUpdatePassword}
            inputValue={inputValue}
            handleChange={handleChange}
            errors={errors}
            handleAddNews={
              FormType === "add" ? handleAddUser : handleUpdateUser
            }
            setInputValue={setInputValue}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            parentType={parentType}
            formType={FormType}
            loading={loading}
            handleOnClose={handleAddNewsClose}
            buttonLoading={buttonLoading}
            solarData={solarData}
            setErrors={setErrors}
          />
        </Modal>
      </div>
      {isModel && (
        <div className="new__imagemodel-xxx">
          <div className="body_model"></div>
          <div className="new-white-box-xxx">
            <div className="header_close-xxx">
              <i
                onClick={() => {
                  setIsModel(!isModel);
                  setAudio("");
                }}
                className="fas fa-window-close modalClose"
              ></i>
            </div>
            <div className="image_body p-7">
              <audio controls>
                <source src={audio} type="audio/mpeg" />
              </audio>
            </div>
          </div>
        </div>
      )}
      <CommonModal
        show={isDescription}
        onHide={handleDescription}
        header="Did You Know That"
        body={handleData?.sortDescription}
        onclick={() => setIsDescription(!isDescription)}
      />
      <Modal
        show={bulkModel}
        onHide={() => setBulkModel(!bulkModel)}
        className="d-flex justify-content-center align-items-center"
      >
        <BulkUploadImage
          setBulkModel={setBulkModel}
          bulkModel={bulkModel}
          handleGetNewsList={handleGetNewsList}
        />
      </Modal>
      <DescriptioModal
        show={isDescriptioModel}
        onHide={handlesortDescription}
        header="Description"
        body={handleData?.description}
        onclick={() => setIsDescriptioModel(!isDescriptioModel)}
      />
      <CommonModal
        show={audioModal}
        onHide={handleAudio}
        header="Audio Text"
        body={handleData?.audioText}
        onclick={() => setAudioModal(!audioModal)}
      />

      <ImageCommonModal
        show={imageModal}
        onHide={newImagefuntion}
        header="Image Preview"
        body={photo}
        onclick={() => setImageModal(!imageModal)}
      />
    </div>
  );
}
