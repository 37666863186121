import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ApiPost } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../_metronic/_assets/sass/layout/_basic.scss";

import logo from "../../../../assets/icon/company-logo.png"
export default function Login() {
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [bool, setBool] = useState({ loader: false });
  const [showPassword, setShowPassword] = useState(false);
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };


  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (e) => {
    setBool({ ...bool, loader: true });
    e.preventDefault();

    if (!loginData.emailId && !loginData.password) {
      setErrors({
        emailId: "Email is required*",
        password: "Password is required*",
        roleId: "6316d71dec63d255184b332c",
      });
    } else if (loginData.emailId === "" && loginData.password === "") {
      setErrors({ ...errors, emailId: "Email is required*" });
    } else if (!loginData.emailId || loginData.emailId === "") {
      setErrors({ ...errors, emailId: "Email is required*" });
    } else if (
      !loginData.emailId ||
      regexEmail.test(loginData.emailId) === false
    ) {
      setErrors({ ...errors, emailId: "Email is not valid*" });
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Password is required*" });
    } else {
      loginData.emailId = loginData.emailId.toLowerCase();
      const body = {
        email: loginData.emailId.toLowerCase(),
        password: loginData.password,
      };

      await ApiPost("user/signin", body)
        .then((res) => {
          authUtil.setToken(res?.data?.payload?.token);
          userUtil.setUserInfo(res?.data?.payload);
          authUtil.set2FA(false);
          history.push("/dashboard");
          window.location.reload();
          toast.success("Login successfully", {
            position: "top-center",
          });
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message
              ? error.response?.data?.message
              : "Oops something wrong",{
                position: "top-center",
              }
          );
        });
    }
    setBool({ ...bool, loader: false });
  };



  
  return (
    <div className="border-aligment" style={{ minWidth: "350px" }}>
      <div
        className="login-form login-signin position-relative"
        id="kt_login_signin_form"
        style={{ maxWidth: "400px", padding: " 20px 40px" }}
      >
        <div
          className="text-center mb-10 mb-lg-10 position-absolute"
          style={{ top: "-245px", right: "1%", width: "100%" }}
        >
          <div onClick={() => history.push("/")}>
            <img alt="" width="300px" src={logo} />
              
          </div>
        </div>
        <div className="text-center mb-10 mb-lg-15">
          <h3 className="font-size-h1">Login</h3>
          <p className="text-muted font-weight-bold">
            Enter your login credentials.
          </p>
          <span className="text-danger h6">{errors.user}</span>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="form-group fv-plugins-icon-container">
            <div className="input-alignment">
              <input
                placeholder="Email"
                type="email"
                className="form-control form-control-solid h-auto py-4"
                name="emailId"
                value={loginData?.emailId}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <span className="text-danger">{errors.emailId}</span>
          </div>
          <div className="input-alignment">
            <div className="d-flex align-items-center form-control form-control-lg form-control-solid ">
              <input
                style={{ outline: "none" }}
                type={showPassword ? "text" : "password"}
                className="w-100 bg-transparent border border-transparent h-100 "
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />

              <div className="cursor-pointer">
                {showPassword ? (
                  <i
                    className="fa fa-eye-slash"
                    aria-hidden="true"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ fontSize: "20px" }}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye"
                    aria-hidden="true"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ fontSize: "20px" }}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <span className="text-danger">{errors.password}</span>

          <>
            <NavLink to="/dashboard">
              <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                <div
                  className="button-alignment-xx"
                  style={{ marginTop: "40px" }}
                >
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    className={`align-items-center d-flex btn btn addbutton font-weight-bold px-9 py-4 my-3`}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <span className="pr-2">Log In</span>

                    {bool.loader && (
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </NavLink>
          </>
        </form>
      </div>
    </div>
  );
}
