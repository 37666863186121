import React from 'react'
import { Modal } from "react-bootstrap";
const ImageCommonModal = ({ show, onHide, header, body, onclick }) => {
  return (
    <div className='modal-alignment-newdesign-height'>
    <Modal
    show={show}
    onHide={onHide}
    className="modal-alignment-newdesign-height"
    centered={true}
  >
    <Modal.Header>
      <p>{header}</p>
      <i
        onClick={onclick}
        className="fa fa-times icon-close"
        aria-hidden="true"
      ></i>
    </Modal.Header>
    <Modal.Body>
   <img src={body} alt='cutomimage'/>
    </Modal.Body>
  </Modal>
  </div>
  )
}

export default ImageCommonModal