import React, { useState } from "react";
import "./solarsystem.scss";
import image1 from "../../../../assets/icon/logos/8242984.png";
import image2 from "../../../../assets/icon/logos/8243067.png";
import image3 from "../../../../assets/icon/logos/8243073.png";
import { useDropzone } from "react-dropzone";
import { useOnChange } from "../../../../hooks/onChangeHooks";
import samplecsv from "../../../../assets/sample.xlsx";
import { ApiPost } from "../../../../helpers/API/ApiData";
import { toast } from "react-toastify";
const BulkUploadImage = (props) => {
  const [loader, setLoader] = useState(false);
  const { bulkModel, setBulkModel ,handleGetNewsList} = props;
  const { inputValue, setErrors, setInputValue, errors } = useOnChange();

  const handleSubmit = () => {
    if (inputValue?.uploadFile) {
      let formdata = new FormData();
      formdata.append("excelFile", inputValue?.uploadFile);

      setLoader(true);
      ApiPost(`TeleScope/insertExcel`, formdata)
        .then((resp) => {
          toast.success("File sucessfully upload");
          setBulkModel(!bulkModel);
          setLoader(false);
          handleGetNewsList();
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setBulkModel(!bulkModel);
          setLoader(false);
        });
    } else {
      setErrors({ ...errors, uploadFile: "Please upload file" });
    }
  };
  const handleDownloadSampleCSV = () => {
    const link = document.createElement("a");
    link.href = samplecsv;
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const validFileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      const filteredFiles = acceptedFiles.filter((file) =>
        validFileTypes.includes(file.type)
      );

      if (filteredFiles.length > 0) {
        setInputValue({ ...inputValue, uploadFile: filteredFiles[0] });
        setErrors({ ...errors, uploadFile: "" });
      } else {
        setErrors({
          ...errors,
          uploadFile: "Only CSV, XLSX, and XLS files are allowed.",
        });
      }
    },
  });

  return (
    <div className="bulkUploadImageMain">
      <h2>Upload a file</h2>
      <p>You can upload your excel sheet to import your service</p>
      <div className="dragAndDrop" {...getRootProps()}>
        <input type="file" {...getInputProps()} />
        <div className="draganddrop-image-alignment">
          {!inputValue?.uploadFile ? (
            <>
              <div className="image-alignment">
                <img src={image1} alt="image1" />
                <img src={image2} alt="image2" />
                <img src={image3} alt="image3" />
              </div>
              <p>Drop files here to upload</p>
            </>
          ) : (
            <span className="image-name">{inputValue?.uploadFile?.name}</span>
          )}
        </div>
      </div>

      <div>
        <span className="errors">{errors?.uploadFile}</span>
        <p>
          Download a simple excel sheet Click{" "}
          <a href="#" onClick={handleDownloadSampleCSV}>
            here
          </a>{" "}
          to download
        </p>
      </div>
      <div className="button-alignment-modal">
        <button
          className="cancel-button"
          onClick={() => {
            setBulkModel(!bulkModel);
          }}
        >
          Cancel
        </button>
        <button className="upload-button" onClick={handleSubmit}>
          Upload{" "}
          {loader && (
            <div class="h-20px spinner-border text-light w-20px ml-2"></div>
          )}
        </button>
      </div>
    </div>
  );
};

export default BulkUploadImage;
