import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import closeicon from "../../../assets/icon/logos/icons8-close-50.png";
import { ApiGet } from "../../../helpers/API/ApiData";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";
const DownloaddataModel = (props) => {
  const {
    setStartDate,
    startDate,
    setEndDate,
    endDate,
    setOpenModal,
    openModal,
  } = props;
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});

  const handlevalidation = () => {
    let newError = {};
    let isValid = true;
    if (!startDate) {
      newError.sdate = "Please select start date";
      isValid = false;
    }
    if (!endDate) {
      newError.edate = "Please select end date";
      isValid = false;
    }
    setError(newError);
    return isValid;
  };
  const handlestartdate = (date) => {
    setStartDate(date);
    setError({ ...error, sdate: "" });
  };
  const handleenddate = (date) => {
    setEndDate(date);
    setError({ ...error, edate: "" });
  };

  const handledownload = () => {
    if (handlevalidation()) {
      ApiGet(`user2/getUserByDate?startDate=${startDate}&endDate=${endDate}`)
        .then((resp) => {
          setLoader(true);
          if (resp?.data?.payload) {
            let newdata = resp.data.payload.map((item) => ({
              "Created date": moment(item?.createdAt).format("YYYY/MM/DD"),
              "User name": item?.name,
              "Phone num": item?.dialcode + " " + item?.phonenum,
            }));
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(newdata);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "userlist.xlsx");
            setOpenModal(!openModal);
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <>
      <div className="download-model-container">
        <div className="download-model">
          <div className="daownload-model-header">
            <div className="download-model-header-close">
              <img
                src={closeicon}
                alt="close"
                onClick={() => setOpenModal(!openModal)}
              />
            </div>
            <p> Download User Data</p>
          </div>
        </div>
        <div className="select-range-box">
          <div className="date-alignment-flex-design">
            <div className="date-alignment">
              <h5>Start Date </h5>
              <span style={{ color: "red" }}> {error?.sdate}</span>
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                selected={startDate}
                onChange={(date) => {
                  handlestartdate(date);
                }}
                selectsStart
                dateFormat="dd MMM yyyy"
                placeholderText="Select start date"
                className="date-picker"
                dropdownMode="select"
              />
            </div>
            <div className="date-alignment">
              <h5>End Date </h5>{" "}
              <span style={{ color: "red" }}> {error?.edate}</span>
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                selected={endDate}
                onChange={(date) => handleenddate(date)}
                selectsEnd
                minDate={
                  startDate &&
                  new Date(startDate.getTime() - 365 * 24 * 60 * 60 * 1000)
                }
                maxDate={
                  startDate &&
                  new Date(startDate.getTime() + 365 * 24 * 60 * 60 * 1000)
                }
                dateFormat="dd MMM yyyy"
                placeholderText="Select end date"
                className="date-picker"
              />
            </div>
          </div>
          <div className="select-range-pera">
            <p>
              A selected date range user data will get downloaded in an excel
              format
            </p>
          </div>
        </div>
        <div className="download-button">
          <button onClick={handledownload}>
            {" "}
            <i class="fas fa-file-download"></i>
            Download
            {loader && (
              <div class="h-20px spinner-border text-light w-20px ml-2"></div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default DownloaddataModel;
