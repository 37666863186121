export const ValidateForm = (inputValue, FormType ,isdrop ) => {
  let error = {};
  let isValid = true;

  if (!inputValue?.name || inputValue?.name?.trim() === "") {
    error.name = "Please enter name ";
    isValid = false;
  }
  if (!inputValue?.description || inputValue?.description?.trim() === "") {
    error.description = "Please enter description";
    isValid = false;
  }
  if (!inputValue?.photo ) {
    error.photo = " upload image";
    isValid = false;
  }

 
  if (!inputValue?.audio || inputValue?.audio?.trim() === "") {
    error.audio = "Please enter text";
    isValid = false;
  }
  if (!inputValue?.audioFile ) {
    error.audioFile = "Please upload audio";
    isValid = false;
  }
  if (inputValue?.positionNo ==="" ) {
    error.positionNo = "Please  enter text";
    isValid = false;
  }

  
 
  return { isValid, error };
};
